import React, { useState } from "react"

import { graphql } from "gatsby"
import styled from "styled-components"

import Loading from "@kiwicom/orbit-components/lib/Loading"

import Container from "components/container"
import Hero from "components/hero"
import Layout from "components/layout"

const LoadingWrapper = styled.div`
  margin-top: 30px;
`

const FormContainer = styled(Container)`
  padding: 0px;
`

export default ({ data }) => {
  const [loading, setLoading] = useState(true)

  return (
    <Layout
      title={"Invest in " + data.site.siteMetadata.title}
      description="Register to stay updated on our plans"
    >
      <Hero
        title={"Invest in " + data.site.siteMetadata.title}
        subtitle="Register for updates on our fundraising plans. We will not send you any other junk."
      />
      <FormContainer>
        {loading && (
          <LoadingWrapper>
            <Loading
              type="boxLoader"
              text="Loading. This can take a few seconds..."
            />
          </LoadingWrapper>
        )}
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSe7YpWbj2sYaMlcONRyTGtAY8LS36dmk65UnM8hbOFAgMbqFg/viewform?embedded=true"
          width="100%"
          height="650"
          frameBorder="0"
          onLoad={() => setLoading(false)}
        >
          Loading…
        </iframe>
      </FormContainer>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

import React from "react"

import styled from "styled-components"

import Container from "components/container"

type HeroWrapperProps = {
  fullHeight?: boolean
  coloredBackground?: boolean
}

type HeroProps = {
  title: string
  subtitle?: string
  children?: React.ReactNode
  fullHeight?: boolean
  coloredBackground?: boolean
}

const HeroWrapper = styled.div<HeroWrapperProps>`
  text-align: center;
  display: flex;
  padding: 50px 0px;
  ${(props) =>
    props.fullHeight &&
    `
      flex: 1;
      & > div {
        margin: auto;
      }
    `}
  ${(props) =>
    props.coloredBackground &&
    `
    background-color: ${props.theme.orbit.paletteProductNormal};
    & h1, p {
      color: white;
    };
    `}
`

const Title = styled.h1`
  margin: 0px;
  line-height: 1.4;
`

const Subtitle = styled.p`
  margin: 20px 0px 0px 0px;
`

const Hero = ({
  title,
  subtitle,
  children,
  fullHeight = false,
  coloredBackground = false,
}: HeroProps) => (
  <HeroWrapper fullHeight={fullHeight} coloredBackground={coloredBackground}>
    <Container size="narrow">
      <Title>{title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {children}
    </Container>
  </HeroWrapper>
)

export default Hero
